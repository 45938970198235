import React from "react";
import thess_logo from "assets/images/thess.png";

import { Container, Heading, Image, Link, Stack, Text } from "@chakra-ui/react";

export function MotTerms() {
  return (
    <Container maxW="xl" my={10}>
      <Image mb={4} maxW="300px" mx="auto" alt="thess logo" src={thess_logo} />
      <Heading size="lg" mb={4}>
        Όροι χρήσης της εφαρμογής «ΠοτίΖΩ»
      </Heading>
      <Stack>
        <Text>
          Ο Δήμος Θεσσαλονίκης για την χρήση της εφαρμογής «ΠοτίΖΩ» δηλώνει
          ρητώς ότι είναι απαραίτητη η αποδοχή των παρακάτω ειδικών όρων:
        </Text>
        <Text>
          - Η εφαρμογή βρίσκεται σε πιλοτική λειτουργία και ενδέχεται να
          παρατηρηθούν προβλήματα κατά τη διάρκεια της χρήση της.
        </Text>
        <Text>
          - Η υιοθεσία για το πότισμα των δένδρων μπορεί να γίνει για το μέγιστο
          αριθμό των τριών (3) δένδρων.
        </Text>
        <Text>
          - Η υπηρεσία δεν ενδείκνυται για κάθε είδους επικοινωνία με τον Δήμο
          Θεσσαλονίκης. Για επείγοντα περιστατικά ή για οποιοδήποτε θέμα
          θεωρείτε, ότι πρέπει να έχετε μια επίσημη απάντηση παρακαλούμε να
          χρησιμοποιήσετε απευθείας το πρωτόκολλο του Δήμου ή τα τηλέφωνα των
          αρμόδιων υπηρεσιών του.
        </Text>
        <Text>
          - Ο χρήστης παραμένει μοναδικός υπεύθυνος για την πραγματοποίηση του
          ποτίσματος του δένδρου που υιοθέτησε. Ο Δήμος Θεσσαλονίκης, ως
          διαχειριστής, διατηρεί το δικαίωμα να χρησιμοποιήσει τα πραγματικά
          στοιχεία του χρήστη, σε περίπτωση νομικής δράσης που προκύπτει από
          χρήση που παραβιάζει τους όρους χρήσης της εφαρμογής «ΠοτίΖΩ».
        </Text>
        <Text>
          - Η δυνατότητα υιοθεσίας δένδρου για πότισμα είναι ελεύθερη. Δεν
          μπορεί να πραγματοποιηθεί σε περίπτωση που το αντίστοιχο δένδρο έχει
          ήδη υιοθετηθεί από άλλον χρήστη. Ωστόσο, ο Δήμος Θεσσαλονίκης μπορεί
          να προβεί άμεσα και χωρίς καμία προηγούμενη ειδοποίηση, καθώς και
          χωρίς την υποχρέωση αιτιολογίας, στην διαγραφή υιοθεσίας αν κρίνει ότι
          δεν πληροί τους όρους και τις προϋποθέσεις της.
        </Text>
        <Text>
          O Δήμος Θεσσαλονίκης κατέχει τα πνευματικά δικαιώματα των φωτογραφιών
          αλλά και των πληροφοριών που δίνονται μέσω της εφαρμογής.
        </Text>
        <Text>
          Αν έχετε διαπιστώσει οποιαδήποτε προβλήματα παρακαλείσθε να μας
          ειδοποιήσετε στο{" "}
          <Link color="blue" href="mailto:info@thessaloniki.gr">
            info@thessaloniki.gr
          </Link>{" "}
          προκειμένου να αντιμετωπισθούν.
        </Text>
        <Text>Τελευταία ενημέρωση: Μάιος 2023</Text>
        <Text align="center">©2023 Δήμος Θεσσαλονίκης</Text>
      </Stack>
    </Container>
  );
}
