import React from "react";
import { Container, Heading, Image, Link, Stack, Text } from "@chakra-ui/react";
import thess_logo from "assets/images/thess.png";

export function MotDataProtection() {
  return (
    <Container maxW="xl" my={10}>
      <Image mb={4} maxW="300px" mx="auto" alt="thess logo" src={thess_logo} />
      <Heading size="md" mb={4}>
        ΕΝΗΜΕΡΩΣΗ, κατά το άρθρο 13 του Γενικού Κανονισμού 679/2016 ΕΕ, σχετικά
        με την επεξεργασία προσωπικών δεδομένων κατά την εγγραφή στη εφαρμογή
        ΠΟΤΙΖΩ.
      </Heading>
      <Stack>
        <Text>
          Το ΝΠΔΔ-ΟΤΑ Α&#39; βαθμού με την επωνυμία{" "}
          <strong>«Δήμος Θεσσαλονίκης»</strong>, που εδρεύει στη Θεσσαλονίκη
          (οδός Λεωφόρος Βας. Γεωργίου 1΄-Τ.Κ. 54640 τηλ.:231331-7777), όπως
          νόμιμα εκπροσωπείται από τον κ. Δήμαρχο του,{" "}
          <strong>ενημερώνει</strong> με το παρόν, και σύμφωνα με τις διατάξεις
          της κείμενης νομοθεσίας περί προστασίας δεδομένων προσωπικού χαρακτήρα
          και ιδιαιτέρως του Γενικού Κανονισμού ΕΕ 679/2016,{" "}
          <strong>υπό την ιδιότητά του ως «Υπεύθυνος Επεξεργασίας»</strong>, το
          φυσικό πρόσωπο που συμπληρώνει την παρούσα φόρμα και υποβάλλει τα
          προσωπικά του δεδομένα (
          <i>εφεξής καλούμενο «Υποκείμενο των Δεδομένων</i>, ότι ο ίδιος ο ΟΤΑ
          και οι αρμόδιες Υπηρεσίες-Διευθύνσεις-Τμήματα αυτού (
          <i>
            όπως και οι υπάλληλοί του, που ενεργούν υπό την εποπτεία του, κατ’
            εντολή και για λογαριασμό του και στα πλαίσιο των αρμοδιοτήτων τους,
            πιθανόν δε και άλλοι ως από κοινού «Υ.Ε.», «Εκτελούντες την
            Επεξεργασία», τρίτοι ή αποδέκτες: υπουργεία, δημόσιες αρχές, ΔΟΥ,
            δικαστικές αρχές κλπ, βάσει συμμόρφωσης με έννομη υποχρέωση του
            «Υπευθύνου Επεξεργασίας» ή σε εκπλήρωση καθήκοντος του ή για
            εκτέλεση σύμβασης
          </i>
          ), <strong>συλλέγει, επεξεργάζεται και τηρεί</strong> τα προσωπικά
          δεδομένα που υποβάλλονται στην παρούσα φόρμα, τα οποία αυτοβούλως και
          για την ικανοποίηση των αιτημάτων του υποβάλλει ο/η αιτών/ουσα -
          «Υποκείμενο των Δικαιωμάτων».
        </Text>

        <Text>
          Τα δεδομένα αυτά (Ηλεκτρονική Διεύθυνση Αλληλογραφίας) θα
          χρησιμοποιηθούν από τον Δήμο <u> ΜΟΝΟΝ</u> για τους εξής σκοπούς
          επεξεργασίας: Την εγγραφή στην εφαρμογή και την επικοινωνία με το
          «Υποκείμενο των δεδομένων» για τις ανάγκες ορθής λειτουργίας της
          εφαρμογής. Τα ως άνω δεδομένα θα χρησιμοποιηθούν για την ανάγκη
          επικοινωνίας του «Υπευθύνου Επεξεργασίας ή του εξουσιοδοτημένου από
          αυτόν υπαλλήλου του ή τρίτου» με το «Υποκείμενο» και τη συμμόρφωση του
          «Υπευθύνου Επεξεργασίας» με πιθανή έννομη υποχρέωσή του (
          <i>άρθρο 6 παρ.1γ’ ΓενΚαν</i>).
        </Text>

        <Text>
          Τα δεδομένα αυτά διατηρούνται και αρχειοθετούνται σε ηλεκτρονικό
          αρχείο από το αρμόδιο Τμήμα του Δήμου Θεσσαλονίκης και δεν
          παρέχεται/προβλέπεται πρόσβαση σε μη εξουσιοδοτημένους υπαλλήλους ή σε
          μη εξουσιοδοτημένους συνεργάτες του Δήμου Θεσσαλονίκης ενώ λαμβάνονται
          και τα απαραίτητα μέτρα για την αποφυγή κάθε μη εξουσιοδοτημένης
          πρόσβασης, χρήσης ή τροποποίησης των δεδομένων αυτών. Ο δε «Υπεύθυνος
          Επεξεργασίας» (Δήμος Θεσσαλονίκης) εφαρμόζει τα κατάλληλα εκείνα
          τεχνικά και οργανωτικά μέτρα για τη διασφάλιση της προστασίας των
          δεδομένων προσωπικού χαρακτήρα. Η παροχή των δεδομένων αυτών είναι
          απαραίτητη για την εγγραφή στη συγκεκριμένη πλατφόρμα και αν δεν
          δοθούν από το «Υποκείμενο των Δεδομένων», η διεκπεραίωση της εγγραφής
          δεν θα είναι δυνατή.
        </Text>

        <Text>
          Το «Υποκείμενο των Δεδομένων» έχει δικαίωμα υποβολής αιτήματος στον
          «Υπεύθυνο Επεξεργασίας» για: πρόσβαση-ενημέρωση, διόρθωση, περιορισμό
          επεξεργασίας των δεδομένων που το αφορούν, αντίταξη στην επεξεργασία
          καθώς και για τη διαγραφή και τη φορητότητα, πάντα υπό τους όρους και
          τους περιορισμούς της κείμενης νομοθεσίας (πχ 17 παρ.3, 20 παρ.3, 23
          ΓενΚαν). Τα δικαιώματα αυτά ασκούνται είτε με τη συμπλήρωση της
          αντίστοιχης αίτησης-φόρμας που υπάρχει διαθέσιμη στις Δνσεις και στην
          ηλεκτρονική ιστοσελίδα του Δήμου, είτε με αποστολή επιστολής στη
          διεύθυνση: «Δήμος Θεσσαλονίκης», (οδός Λεωφόρος Βας. Γεωργίου 1΄-Τ.Κ.
          54640 τηλ.:231331-7777), είτε τέλος με ηλεκτρονικό μήνυμα στη
          διεύθυνση:{" "}
          <Link color="blue" href="mailto:dpo@thessaloniki.gr">
            dpo@thessaloniki.gr
          </Link>
          .
        </Text>
        <Text>
          Ο «Υπεύθυνος Επεξεργασίας» παρέχει στο «Υποκείμενο των Δεδομένων»
          πληροφορίες για την ενέργεια που πραγματοποιείται κατόπιν αιτήματος,
          δυνάμει των άρθρων 15 έως 22 ΓενΚαν, χωρίς καθυστέρηση και σε κάθε
          περίπτωση <strong>εντός μηνός</strong> από την παραλαβή του αιτήματος.
          Η εν λόγω προθεσμία <strong>δύναται</strong> να παραταθεί κατά δύο
          ακόμη μήνες, εφόσον απαιτείται, λαμβανομένων υπόψη της πολυπλοκότητας
          του αιτήματος και του αριθμού των αιτημάτων (
          <i>βλ. αναλυτικότερα: άρθρο 12 παρ. 3-4 ΓενΚαν</i>). Επίσης, για τυχόν
          καταγγελία, το «Υποκείμενο των Δεδομένων» έχει το δικαίωμα να
          απευθυνθεί εγγράφως στην{" "}
          <strong>Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα</strong> (
          <i>Δνση: Κηφισίας 1-3, Τ.Κ. 115 23, Αθήνα</i>) είτε με ηλεκτρονικό
          μήνυμα (
          <Link isExternal color="blue" href="http://www.dpa.gr">
            www.dpa.gr
          </Link>
          ).
        </Text>
      </Stack>
    </Container>
  );
}
